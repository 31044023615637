
import { Action, Getter } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { bus } from "@/main";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormError,
        FormSubmit,
    },
})
export default class DeleteAppointmentModal extends Vue {
    @Action("appointment/delete") deleteAppointment!: (id: number) => void;

    @Ref() form!: FormClass;

    appointment_id: number | null = null;

    show = false;

    errorResponse: ErrorResponse = {
        status: 0,
        errors: [],
    };

    mounted() {
        bus.$off("show-delete-appointment");
        bus.$on("show-delete-appointment", (appointment_id: number) => {            
            this.show = true;
            this.appointment_id = appointment_id;
        });
    }

    async submit(form: FormClass) {
        if (!this.appointment_id) {
            return;
        }

        try {
            await this.deleteAppointment(this.appointment_id);

            this.$toast.open({ message: this.$t("views.appointments.deleted") as string, type: "success", position: "bottom-right" });

            this.handleClose();
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
